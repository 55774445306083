import { String } from 'typescript-string-operations';
import * as i0 from "@angular/core";
import * as i1 from "./core-data.service";
var UNDERLINE = '_';
var FORWARD_SLASH = '/';
var FORWARD_SLASH_REGEX_PATTERN = /\//g;
var RESOURCE_TYPE_REGEX_PATTERN = /(^\/)|(\/$)/g;
var GENERAL_RESOURCE_TYPE = 'GeneralResource';
var CONFIGURATION_PARAMETER_RESOURCE_KEY = 'configurationparameter';
var ResourceService = /** @class */ (function () {
    function ResourceService(coreData) {
        this.coreData = coreData;
        this.cultureCode = 'tr-tr';
    }
    ResourceService.prototype.generateResourceId = function (type, key) {
        return type.toLowerCase().concat(UNDERLINE).concat(this.cultureCode.toLowerCase()).concat(UNDERLINE).concat(key.toLowerCase());
    };
    ResourceService.prototype.generateSiriusResourceType = function (resourceType) {
        var resourceText;
        if (resourceType === FORWARD_SLASH) {
            resourceText = GENERAL_RESOURCE_TYPE;
        }
        else {
            resourceText = resourceType.replace(RESOURCE_TYPE_REGEX_PATTERN, String.Empty).trim().replace(FORWARD_SLASH_REGEX_PATTERN, UNDERLINE);
        }
        return resourceText;
    };
    ResourceService.prototype.getResource = function (key, type) {
        if (type === void 0) { type = GENERAL_RESOURCE_TYPE; }
        return Object.assign([], this.coreData.resource[this.generateResourceId(type, key)]);
    };
    ResourceService.prototype.getComponentContent = function (type, key) {
        var resources = this.getResource(key, this.generateSiriusResourceType(type));
        return resources ? resources : [];
    };
    ResourceService.prototype.getConfigurationParameter = function (key) {
        var configurationParameter = this.getResource(CONFIGURATION_PARAMETER_RESOURCE_KEY);
        return configurationParameter ? configurationParameter[key] : null;
    };
    ResourceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResourceService_Factory() { return new ResourceService(i0.ɵɵinject(i1.CoreDataService)); }, token: ResourceService, providedIn: "root" });
    return ResourceService;
}());
export { ResourceService };
