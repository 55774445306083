import { Component } from '@angular/core';
import { ImageConstants } from '../../constants/image-constants';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { RouteConstants } from '../../constants/route-constants';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent {

  RouteConstants = RouteConstants;
  Images = ImageConstants;
  sideNavOpen = false;
  faBars = faBars;

  constructor() {
    super();
  }
}
