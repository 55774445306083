import Logo from '../../../assets/images/logo.png';
import HeaderBackground from '../../../assets/images/header-wrapper-bg.jpg';
import HelpDummyImg from '../../../assets/images/help_dummy_img.png';
import ChevronLeft from '../../../assets/images/chevron_left_solid_ruby.svg';
import ChevronRight from '../../../assets/images/chevron_right_solid_ruby.svg';
import ResultSuccess from '../../../assets/images/basarili_sonuc_icon.png';
import ResultFail from '../../../assets/images/basarisiz_sonuc_icon.png';
import StartApplication from '../../../assets/images/basvuru_basla_icon.png';
import AtmMapIcon from '../../../assets/images/atm_map_icon.png';
import BranchMapIcon from '../../../assets/images/sube_map_icon.png';
import BranchMapIconRuby from '../../../assets/images/sube_map_icon_ruby.png';
import CheckInfoBankCodeImg from '../../../assets/images/CekInfo/BankaKodu.jpg';
import CheckInfoCheckBranchCodeImg from '../../../assets/images/CekInfo/BankaSubeKodu.jpg';
import CheckInfoCheckNoImg from '../../../assets/images/CekInfo/CekNo.jpg';
import CheckInfoAccountNoImg from '../../../assets/images/CekInfo/HesapNo.jpg';
import CheckInfoDrawerBankInfoImg from '../../../assets/images/CekInfo/KesideciBankaBilgileri.jpg';




export class ImageConstants {
    public static Logo = Logo;
    public static HeaderBackground = HeaderBackground;
    public static HelpDummyImg = HelpDummyImg;
    public static ChevronLeft = ChevronLeft;
    public static ChevronRight = ChevronRight;
    public static ResultSuccess = ResultSuccess;
    public static ResultFail = ResultFail;
    public static StartApplication = StartApplication;
    public static AtmMapIcon = AtmMapIcon;
    public static BranchMapIcon = BranchMapIcon;
    public static BranchMapIconRuby = BranchMapIconRuby;
    public static CheckInfoBankCodeImg = CheckInfoBankCodeImg;
    public static CheckInfoCheckBranchCodeImg = CheckInfoCheckBranchCodeImg;
    public static CheckInfoCheckNoImg = CheckInfoCheckNoImg;
    public static CheckInfoAccountNoImg = CheckInfoAccountNoImg;
    public static CheckInfoDrawerBankInfoImg = CheckInfoDrawerBankInfoImg;

}
