<app-custom-modal
  [modalRef]="modalRef"
  [title]="digitalBankingTitleText"
  (closed)="close($event)"
>

<div class="width-md center">

    <form
        #dbForm = "ngForm"
        (ngSubmit) = "dbForm.form.valid ? clickSubmitButton() : (isSubmitClicked = true)"
    >
        <app-custom-input
          [label]="formDigitalBankingGDPRLabel"
          [errorMessage]="formDigitalBankingGDPRErrorText"
          [isSubmitClicked]="isSubmitClicked"
          [element]="digitalBankingGDPRRef"
          [elementModel]="digitalBankingGDPRModelRef"
          [withHyperLink]="true"
        >
          <input
            id="digitalBankingForm"
            type="checkbox"
            name="digitalBankingForm"
            required
            [(ngModel)]="digitalBankingForm"
            #digitalBankingGDPRRef
            #digitalBankingGDPRModelRef="ngModel"
          />
        </app-custom-input>

        <div>
            <a  style="padding-left: 22px;white-space: pre" href="https://www.qnbff.com/gizlilik-ve-cerez-politikasi" target="_blank"><u>Uygulamalarımıza ait kullanım koşullarına buradan ulaşabilirsiniz.</u></a>
        </div>

        <!-- <label>{{ formDigitalBankingAPRLabel }}</label> -->

        <br>

        <div class="text-center">
            <button
              type="submit"
              class="btn btn-finans-ruby md-button"
              (click)="dbForm.form.valid ? clickSubmitButton() : (isSubmitClicked = true)"
            >
              {{ formDigitalBankingSubmitButtonText }}
            </button>
        </div>

    </form>


</div>
