import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base/base.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent {

  // CMS
  resourceType = 'footer';
  resourceKey = 'info';
  //
  addressSpan: string;
  phoneSpan: string;
  faxSpan: string;
  addressText: string;
  phoneText: string;
  faxText: string;
  //

  constructor() {
    super();
  }
}
