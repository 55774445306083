import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { faBars, faHome, faQuestionCircle, faMapMarkerAlt, faHistory, faCog } from '@fortawesome/free-solid-svg-icons';
import { RouteConstants } from '../../constants/route-constants';
import { NavigationEnd } from '@angular/router';
var NavigationMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NavigationMenuComponent, _super);
    function NavigationMenuComponent() {
        var _this = _super.call(this) || this;
        // CMS
        _this.resourceType = 'generalresource';
        _this.resourceKey = 'header';
        //
        _this.RouteConstants = RouteConstants;
        _this.currentUrl = '';
        _this.faBars = faBars;
        _this.faHome = faHome;
        _this.faQuestionCircle = faQuestionCircle;
        _this.faMapMarkerAlt = faMapMarkerAlt;
        _this.faHistory = faHistory;
        _this.faCog = faCog;
        _this.isMenuOpenChange = new EventEmitter();
        _this.subscribeRouterService();
        return _this;
    }
    NavigationMenuComponent.prototype.subscribeRouterService = function () {
        var _this = this;
        this.routerService.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.currentUrl = event.url;
                console.log(event.url);
            }
        });
    };
    NavigationMenuComponent.prototype.sideNavToggle = function () {
        if (!this.isSideMenu) {
            return;
        }
        this.isMenuOpen = false;
        this.isMenuOpenChange.emit(this.isMenuOpen);
    };
    return NavigationMenuComponent;
}(BaseComponent));
export { NavigationMenuComponent };
