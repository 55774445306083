import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { BaseComponent } from 'src/app/core/components/base/base.component';
var DigitalbankingKvkkModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DigitalbankingKvkkModalComponent, _super);
    //
    function DigitalbankingKvkkModalComponent(modalRef) {
        var _this = _super.call(this) || this;
        _this.modalRef = modalRef;
        // CMS
        _this.resourceType = 'generalresource';
        _this.resourceKey = 'digitalbankingkvkkmodal';
        //output element
        _this.outputSubject = new Subject();
        return _this;
    }
    DigitalbankingKvkkModalComponent.prototype.clickSubmitButton = function () {
        this.outputSubject.next(true);
        this.outputSubject.complete();
        this.modalRef.hide();
    };
    DigitalbankingKvkkModalComponent.prototype.close = function (isClosed) {
        this.outputSubject.next(false);
        this.outputSubject.complete();
        this.modalRef.hide();
        //this.closeSubject.next(true);
        //this.closeSubject.complete();
    };
    return DigitalbankingKvkkModalComponent;
}(BaseComponent));
export { DigitalbankingKvkkModalComponent };
