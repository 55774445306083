import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteConstants } from './shared/constants/route-constants';


const routes: Routes = [
  {
    path: '',
    redirectTo: RouteConstants.APPLICATION,
    pathMatch: 'full'
  },
  {
    path: RouteConstants.APPLICATION,
    loadChildren: () => import('./pages/application-page/application-page.module').then(m => m.ApplicationPageModule)
  },
  {
    path: RouteConstants.HELP,
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule)
  },
  {
    path: RouteConstants.CONTACT_US,
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: RouteConstants.REDIRECTED_MESSAGE,
    loadChildren: () => import('./pages/redirected-message-page/redirected-message-page.module').then(m => m.RedirectedMessagePageModule)
  },
  {
    path: RouteConstants.SETTINGS,
    loadChildren: () => import('./pages/settings-page/settings-page.module').then(m => m.SettingsPageModule)
  },
  {
    path: RouteConstants.TRANSACTIONS,
    loadChildren: () => import('./pages/transactions/transactions.module').then(m => m.TransactionsModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
