/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/header/header.component.ngfactory";
import * as i3 from "./shared/components/header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "./shared/components/footer/footer.component.ngfactory";
import * as i6 from "./shared/components/footer/footer.component";
import * as i7 from "../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i8 from "ngx-spinner";
import * as i9 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 4964352, null, 0, i3.HeaderComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "container-fluid"], ["id", "mainContent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-footer", [], null, null, null, i5.View_FooterComponent_0, i5.RenderType_FooterComponent)), i1.ɵdid(6, 4964352, null, 0, i6.FooterComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ngx-spinner", [["bdColor", "rgba(72,0,42,0.8)"], ["bdOpacity", "1"], ["color", "#fff"], ["size", "medium"], ["type", "timer"]], null, null, null, i7.View_NgxSpinnerComponent_0, i7.RenderType_NgxSpinnerComponent)), i1.ɵdid(8, 770048, null, 0, i8.NgxSpinnerComponent, [i8.NgxSpinnerService, i1.ChangeDetectorRef], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"], fullScreen: [4, "fullScreen"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 0, "p", [["style", "color: white"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); var currVal_0 = "rgba(72,0,42,0.8)"; var currVal_1 = "medium"; var currVal_2 = "#fff"; var currVal_3 = "timer"; var currVal_4 = true; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i9.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
