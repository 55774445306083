import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ModalButtonModel } from '../../models/modal-button.model';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent {
  @Input() title: string;
  @Input() body: string;
  @Input() icon: IconDefinition;
  @Input() firstButtonText: string;
  @Input() secondButtonText: string;
  buttonSubject: Subject<ModalButtonModel> = new Subject<ModalButtonModel>();
  //
  constructor(public modalRef: BsModalRef) {}
  //
  close(isClosed: boolean) {
    this.buttonAction({ close: true, first: false, second: false });
  }

  buttonAction(buttons: ModalButtonModel) {
    this.modalRef.hide();
    this.buttonSubject.next(buttons);
    this.buttonSubject.complete();
  }
}
