import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { String } from 'typescript-string-operations';
import { CoreConstants } from '../constants/core-constants';
import * as i0 from "@angular/core";
import * as i1 from "./core-data.service";
var HttpInterceptorService = /** @class */ (function () {
    function HttpInterceptorService(coreData) {
        this.coreData = coreData;
    }
    HttpInterceptorService.prototype.intercept = function (req, next) {
        console.log(JSON.stringify(req));
        var token = this.coreData.token;
        if (token) {
            var tokenStr = String.Format(CoreConstants.COOKIE_TOKEN_VALUE, token.type.toTitleCase(), token.token);
            req = req.clone({ headers: req.headers.set('Authorization', tokenStr) });
        }
        req = req.clone({ headers: req.headers.set('Cache-Control', 'no-cache') });
        req = req.clone({ headers: req.headers.set('Pragma', 'no-cache') });
        req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        req = req.clone({ withCredentials: true });
        // Response
        return next.handle(req).pipe(catchError(function (error) {
            return throwError(error);
        }));
    };
    HttpInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpInterceptorService_Factory() { return new HttpInterceptorService(i0.ɵɵinject(i1.CoreDataService)); }, token: HttpInterceptorService, providedIn: "root" });
    return HttpInterceptorService;
}());
export { HttpInterceptorService };
