import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { CoreDataService } from './core-data.service';
import { CoreConstants } from '../constants/core-constants';

@Injectable()
export class CanActivateViaAuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private coreData: CoreDataService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    this.coreData.setState(CoreConstants.LOGIN_REDIRECT, state.url);
    this.router.navigate([]);
    return false;
  }
}
