import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { BaseComponent } from 'src/app/core/components/base/base.component';

@Component({
  selector: 'app-digitalbanking-kvkk-modal',
  templateUrl: './digitalbanking-kvkk-modal.component.html',
  styleUrls: ['./digitalbanking-kvkk-modal.component.scss']
})
export class DigitalbankingKvkkModalComponent extends BaseComponent{

  // CMS
  resourceType = 'generalresource';
  resourceKey = 'digitalbankingkvkkmodal';

  //html element variables
  @Input() digitalBankingTitleText: string;
  @Input() formDigitalBankingSubmitButtonText: string;
  @Input() formDigitalBankingGDPRLabel: string;
  @Input() formDigitalBankingGDPRErrorText: string;
  @Input() formDigitalBankingAPRLabel: string;
  @Input() isSubmitClicked: boolean;
  @Input() digitalBankingForm: {};

  //output element
  outputSubject: Subject<boolean> = new Subject<boolean>();

  //
  constructor(public modalRef: BsModalRef) { 
    super();
  }

  clickSubmitButton() {
    this.outputSubject.next(true);
    this.outputSubject.complete();
    this.modalRef.hide();
  }

  close(isClosed: boolean) {
    this.outputSubject.next(false);
    this.outputSubject.complete();
    this.modalRef.hide();
    //this.closeSubject.next(true);
    //this.closeSubject.complete();
  }

}
