import * as i0 from "@angular/core";
var CoreDataService = /** @class */ (function () {
    function CoreDataService() {
        this.state = {};
        this.resource = {};
    }
    // State
    CoreDataService.prototype.setState = function (key, value) {
        if (key) {
            this.state[key] = value;
        }
    };
    CoreDataService.prototype.getState = function (key) {
        if (key) {
            return this.state[key];
        }
    };
    CoreDataService.prototype.deleteState = function (key) {
        if (key) {
            delete this.state[key];
        }
    };
    CoreDataService.prototype.clearState = function () {
        return this.state = {};
    };
    CoreDataService.prototype.getAllState = function () {
        console.log(this.state);
        return this.state;
    };
    CoreDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoreDataService_Factory() { return new CoreDataService(); }, token: CoreDataService, providedIn: "root" });
    return CoreDataService;
}());
export { CoreDataService };
