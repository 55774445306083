/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./custom-modal.component";
var styles_CustomModalComponent = [i0.styles];
var RenderType_CustomModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomModalComponent, data: {} });
export { RenderType_CustomModalComponent as RenderType_CustomModalComponent };
export function View_CustomModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "modal-header"]], [[2, "with-label", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row title-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(10, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.faTimesCircle; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10).title; var currVal_3 = i1.ɵnov(_v, 10).renderedIconHTML; _ck(_v, 9, 0, currVal_2, currVal_3); }); }
export function View_CustomModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-modal", [], null, null, null, View_CustomModalComponent_0, RenderType_CustomModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.CustomModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomModalComponentNgFactory = i1.ɵccf("app-custom-modal", i5.CustomModalComponent, View_CustomModalComponent_Host_0, { title: "title", modalRef: "modalRef" }, { closed: "closed" }, ["*"]);
export { CustomModalComponentNgFactory as CustomModalComponentNgFactory };
