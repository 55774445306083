<app-custom-modal [modalRef]="modalRef" [title]="titleText" (closed)="close($event)">
  <div class="width-sm center">
    <form
      #sForm="ngForm"
      (ngSubmit)="
        sForm.form.valid ? clickSumbitButton() : (isSubmitClicked = true)
      "
    >
      <app-custom-input
        [errorMessage]="formSmsErrorText"
        [isSubmitClicked]="isSubmitClicked"
        [element]="emailRef"
        [elementModel]="emailModelRef"
      >
        <input
          id="smsForm.smsInput"
          type="tel"
          name="smsForm.smsInput"
          autofocus
          required
          [placeholder]="formSmsPlaceholderText"
          [(ngModel)]="smsForm.smsInput"
          #emailRef
          #emailModelRef="ngModel"
        />
      </app-custom-input>
      <div class="text-center">
        <button
          type="submit"
          class="btn btn-finans-ruby md-button"
          (click)="
            sForm.form.valid ? clickSumbitButton() : (isSubmitClicked = true)
          "
        >
          {{ formSmsSubmitButtonText }}
        </button>
      </div>
    </form>
  </div>
</app-custom-modal>
