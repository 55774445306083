<div class="row">
  <div
    class="side-content"
    *ngIf="sideNavOpen"
    (click)="onContentClick()"
  ></div>

  <app-navigation-menu
    class="side-nav"
    *ngIf="sideNavOpen"
    [isSideMenu]="true"
    [isMenuOpen]="sideNavOpen"
    (isMenuOpenChange)="onSideNavClick()"
    [@inOutAnimation]
  ></app-navigation-menu>

  <button
    class="navbar-toggler ruby-text"
    type="button"
    *ngIf="sideNavOpen"
    (click)="onSideNavClick()"
  >
    <fa-icon [icon]="faBars"></fa-icon>
  </button>
</div>
