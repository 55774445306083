import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { trLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgxImageCompressService} from 'ngx-image-compress';

import { CoreModule } from '../core/core.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ApplicationWizardComponent } from './components/application-wizard/application-wizard.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { CustomContainerComponent } from './components/custom-container/custom-container.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { InputFileImageComponent } from './components/input-file-image/input-file-image.component';
import { ApplicationCheckPreviewCardComponent } from './components/application-check-preview-card/application-check-preview-card.component';
import { ApplicationInvoicePreviewCardComponent } from './components/application-invoice-preview-card/application-invoice-preview-card.component';
import { CheckFormContainerComponent } from './components/check-form-container/check-form-container.component';
import { CheckImageContainerComponent } from './components/check-image-container/check-image-container.component';
import { CustomMessageComponent } from './components/custom-message/custom-message.component';
import { SmsModalComponent } from './components/sms-modal/sms-modal.component';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { TransactionCheckPreviewCardComponent } from './components/transaction-check-preview-card/transaction-check-preview-card.component';
import { TransactionImagePreviewCardComponent } from './components/transaction-image-preview-card/transaction-image-preview-card.component';
import { DocumentId2base64Pipe } from './pipes/document-id2base64.pipe';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { InfoButtonComponent } from './components/info-button/info-button.component';
import { DigitalbankingKvkkModalComponent } from './components/digitalbanking-kvkk-modal/digitalbanking-kvkk-modal.component';

export const options: Partial<IConfig> = {};

@NgModule({
  declarations: [HeaderComponent, FooterComponent, ApplicationWizardComponent, CustomInputComponent, SideNavigationComponent, CustomContainerComponent, NavigationMenuComponent, InputFileImageComponent, ApplicationCheckPreviewCardComponent, ApplicationInvoicePreviewCardComponent, CheckFormContainerComponent, CheckImageContainerComponent, CustomMessageComponent, SmsModalComponent, CustomModalComponent, TransactionCheckPreviewCardComponent, TransactionImagePreviewCardComponent, DocumentId2base64Pipe, MessageModalComponent, InfoButtonComponent, DigitalbankingKvkkModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FontAwesomeModule,
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(options),
    CoreModule
  ],
  providers: [
    NgxImageCompressService
  ],
  exports: [
    FontAwesomeModule,
    HeaderComponent,
    FooterComponent,
    ApplicationWizardComponent,
    CustomInputComponent,
    CustomContainerComponent,
    InputFileImageComponent,
    ApplicationCheckPreviewCardComponent,
    ApplicationInvoicePreviewCardComponent,
    CheckFormContainerComponent,
    CheckImageContainerComponent,
    CustomMessageComponent,
    SmsModalComponent,
    MessageModalComponent,
    TransactionCheckPreviewCardComponent,
    TransactionImagePreviewCardComponent,
    InfoButtonComponent,
    DigitalbankingKvkkModalComponent
  ],
  entryComponents: [
    SmsModalComponent,
    MessageModalComponent,
    DigitalbankingKvkkModalComponent
  ]
})
export class SharedModule {
  constructor(private localeService: BsLocaleService) {
    trLocale.invalidDate = '';
    defineLocale('tr', trLocale);
    localeService.use('tr');
  }
 }
