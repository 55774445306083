import { Component, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { SmsModalFormModel } from 'src/app/shared/models/sms-modal-form.model';
@Component({
  selector: 'app-sms-modal',
  templateUrl: './sms-modal.component.html',
  styleUrls: ['./sms-modal.component.scss']
})
export class SmsModalComponent extends BaseComponent {

  // CMS
  resourceType = 'generalresource';
  resourceKey = 'smsmodal';
  //
  titleText: string;
  formSmsPlaceholderText: string;
  formSmsErrorText: string;
  formSmsDescriptionText: string;
  formSmsSubmitButtonText: string;
  //
  outputSubject: Subject<string> = new Subject<string>();
  closeSubject: Subject<boolean> = new Subject<boolean>();
  smsForm: SmsModalFormModel = {
    smsInput: ''
  };
  isSubmitClicked = false;
  constructor(public modalRef: BsModalRef) {
    super();
  }

  clickSumbitButton() {
    this.outputSubject.next(this.smsForm.smsInput);
    this.outputSubject.complete();
    this.modalRef.hide();
  }

  close(isClosed: boolean) {
    this.closeSubject.next(true);
    this.closeSubject.complete();
  }

}
