import { Injectable } from '@angular/core';
import { String } from 'typescript-string-operations';
import { KeyValuePair } from '../models/keyValuePair.model';
import { CoreDataService } from './core-data.service';

const UNDERLINE = '_';
const FORWARD_SLASH = '/';
const FORWARD_SLASH_REGEX_PATTERN = /\//g;
const RESOURCE_TYPE_REGEX_PATTERN = /(^\/)|(\/$)/g;
const GENERAL_RESOURCE_TYPE = 'GeneralResource';
const CONFIGURATION_PARAMETER_RESOURCE_KEY = 'configurationparameter';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  cultureCode = 'tr-tr';
  constructor(private coreData: CoreDataService) { }

  private generateResourceId(type: string, key: string): string {
    return type.toLowerCase().concat(UNDERLINE).concat(this.cultureCode.toLowerCase()).concat(UNDERLINE).concat(key.toLowerCase());
  }

  private generateSiriusResourceType(resourceType: string): string {
    let resourceText: string;
    if (resourceType === FORWARD_SLASH) {
      resourceText = GENERAL_RESOURCE_TYPE;
    } else {
      resourceText = resourceType.replace(RESOURCE_TYPE_REGEX_PATTERN, String.Empty).trim().replace(FORWARD_SLASH_REGEX_PATTERN, UNDERLINE);
    }
    return resourceText;
  }

  getResource<T>(key: string, type: string = GENERAL_RESOURCE_TYPE): T {
    return Object.assign([], this.coreData.resource[this.generateResourceId(type, key)]) as T;
  }

  getComponentContent(type: string, key: string): KeyValuePair[] {
    const resources = this.getResource<any>(key, this.generateSiriusResourceType(type));
    return resources ? resources : [];
  }

  getConfigurationParameter<T>(key: string): any {
    const configurationParameter = this.getResource(CONFIGURATION_PARAMETER_RESOURCE_KEY);
    return configurationParameter ? configurationParameter[key] as T : null;
  }
}
