import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

const anchorTag = 'A';
const anchorHrefAttribute = 'href';
const slash = '/';

@Directive({
  selector: '[appRouterTransformer]'
})
export class RouterTransformerDirective {
  constructor(private el: ElementRef, private router: Router) {}

  @HostListener('click', ['$event'])
  public onClick(event: any) {
    if (event.target.tagName === anchorTag) {
      const link = event.target.getAttribute(anchorHrefAttribute) as string;
      if (link[0] === slash) {
        this.router.navigate([link]);
        event.preventDefault();
      }
    } else {
      return;
    }
  }
}
