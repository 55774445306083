import * as tslib_1 from "tslib";
import { ImageConstants } from '../../constants/image-constants';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { RouteConstants } from '../../constants/route-constants';
var HeaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HeaderComponent, _super);
    function HeaderComponent() {
        var _this = _super.call(this) || this;
        _this.RouteConstants = RouteConstants;
        _this.Images = ImageConstants;
        _this.sideNavOpen = false;
        _this.faBars = faBars;
        return _this;
    }
    return HeaderComponent;
}(BaseComponent));
export { HeaderComponent };
