import { Component, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { String } from 'typescript-string-operations';
import { InputTypeEnum } from '../../models/enums.model';
import { GeneralConstants } from '../../constants/general-constants';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent extends BaseComponent {
  @Input() label: string;
  @Input() element: Element | any;
  @Input() elementModel: NgModel;
  @Input() errorMessage: string;
  @Input() isSubmitClicked: boolean;
  @Input() withHyperLink = false;
  @Input() minNumber: number;
  @Input() maxNumber: number;
  //
  constructor() {
    super();
  }
  customOnInit() {
    this.addClass(this.element);
    if (this.minNumber || this.maxNumber) {
      this.controlMinMaxNumber(
        this.elementModel,
        this.minNumber,
        this.maxNumber
      );
    }
  }

  customOnChanges(changes: any) {
    if (changes.isSubmitClicked && this.isSubmitClicked && this.element) {
      this.element.className += ' ' + GeneralConstants.INPUT_CLASS_INVALID;
      this.element.className = String.Format(
        GeneralConstants.INPUT_ADD_CLASS_FORMAT,
        this.element.className,
        GeneralConstants.INPUT_CLASS_INVALID
      );
    }
  }

  addClass(element: Element) {
    if (!element) {
      return;
    }
    if (
      element[GeneralConstants.INPUT_ATTRIBUTE_TYPE] === InputTypeEnum.Checkbox
    ) {
      element.className = String.Format(
        GeneralConstants.INPUT_ADD_CLASS_FORMAT,
        element.className,
        GeneralConstants.INPUT_CLASS_CUSTOM_CONTROL
      );
    } else if (
      !element.className.includes(GeneralConstants.INPUT_CLASS_FORM_CONTROL)
    ) {
      element.className = String.Format(
        GeneralConstants.INPUT_ADD_CLASS_FORMAT,
        element.className,
        GeneralConstants.INPUT_CLASS_FORM_CONTROL
      );
    }
  }

  controlMinMaxNumber(model: NgModel, min: number, max: number) {
    model.control.valueChanges.subscribe(value => {
      if (+value < min) {
        model.control.setErrors({ min: true });
      } else if (max && +value > max) {
        model.control.setErrors({ max: true });
      } else {
        model.control.setErrors(null);
      }
    });
  }
}
