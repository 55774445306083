/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "ngx-bootstrap/collapse";
import * as i6 from "@angular/animations";
import * as i7 from "../navigation-menu/navigation-menu.component.ngfactory";
import * as i8 from "../navigation-menu/navigation-menu.component";
import * as i9 from "../side-navigation/side-navigation.component.ngfactory";
import * as i10 from "../side-navigation/side-navigation.component";
import * as i11 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "nav", [["class", "navbar navbar-expand-lg navbar-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "navbar-brand"], ["href", "https://www.qnbff.com/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-controls", "collapseBasic"], ["class", "navbar-toggler"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.sideNavOpen = !_co.sideNavOpen) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(6, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "navbar-collapse collapse show"], ["id", "collapseBasic"]], [[2, "collapse", null], [2, "in", null], [2, "show", null], [1, "aria-expanded", 0], [1, "aria-hidden", 0], [2, "collapsing", null]], null, null, null, null)), i1.ɵdid(8, 8404992, null, 0, i5.CollapseDirective, [i1.ElementRef, i1.Renderer2, i6.AnimationBuilder], { isAnimated: [0, "isAnimated"], collapse: [1, "collapse"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-navigation-menu", [["class", "ml-auto"]], null, null, null, i7.View_NavigationMenuComponent_0, i7.RenderType_NavigationMenuComponent)), i1.ɵdid(10, 4964352, null, 0, i8.NavigationMenuComponent, [], { isSideMenu: [0, "isSideMenu"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-side-navigation", [["class", ".d-lg-none"]], null, [[null, "sideNavOpenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sideNavOpenChange" === en)) {
        var pd_0 = ((_co.sideNavOpen = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_SideNavigationComponent_0, i9.RenderType_SideNavigationComponent)), i1.ɵdid(12, 4964352, null, 0, i10.SideNavigationComponent, [], { sideNavOpen: [0, "sideNavOpen"] }, { sideNavOpenChange: "sideNavOpenChange" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.faBars; _ck(_v, 6, 0, currVal_3); var currVal_10 = true; var currVal_11 = true; _ck(_v, 8, 0, currVal_10, currVal_11); var currVal_12 = false; _ck(_v, 10, 0, currVal_12); var currVal_13 = _co.sideNavOpen; _ck(_v, 12, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Images.Logo; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).title; var currVal_2 = i1.ɵnov(_v, 6).renderedIconHTML; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 8).isCollapse; var currVal_5 = i1.ɵnov(_v, 8).isExpanded; var currVal_6 = i1.ɵnov(_v, 8).isExpanded; var currVal_7 = i1.ɵnov(_v, 8).isExpanded; var currVal_8 = i1.ɵnov(_v, 8).isCollapsed; var currVal_9 = i1.ɵnov(_v, 8).isCollapsing; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 4964352, null, 0, i11.HeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i11.HeaderComponent, View_HeaderComponent_Host_0, { resourceKey: "resourceKey", resourceType: "resourceType" }, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
