export enum InputTypeEnum {
    Undefined = '',
    Button = 'button',
    Checkbox = 'checkbox',
    Color = 'color',
    Date = 'date',
    DatetimeLocal = 'datetime-local',
    Email = 'email',
    File = 'file',
    Hidden = 'hidden',
    Image = 'image',
    Month = 'month',
    Number = 'number',
    Password = 'password',
    Radio = 'radio',
    Range = 'range',
    Reset = 'reset',
    Search = 'search',
    Submit = 'submit',
    Tel = 'tel',
    Text = 'text',
    Time = 'time',
    Url = 'url',
    Week = 'week',
}

export enum ImageHeightClassEnum {
    Undefined,
    Small,
    Medium,
    Large
}

export enum GridBreakPoints {
    xs = 0,
    sm = 576,
    md = 768,
    lg = 992,
    xl = 1200
}

export enum ApplicationStartEnum {
    Undefined,
    Success = '1',
    PopupLimit = '3',
    Popup5 = '5',
    Popup9 = '9',
    NeedVkn2 = '2',
    NeedVkn6 = '6',
    NeedEmail = '4',
}

export enum ApplicationStateEnum {
    Undefined,
    Login,
    Start,
    Vkn,
    Email,
    CheckForm,
    CheckPhoto,
    InvoicePhoto,
    Result,
    StartWithAdvertisement,
    CheckFormWithAdvertisement,
    VknWithAdvertisement,
    EmailWithAdvertisement,
    CheckFromWithAdvertisement,
    CheckPhotoWithAdvertisement,
    InvoicePhotoWithAdvertisement,
    ResultWithAdvertisement
}

export enum ContactUsChildEnum {
    Branch = 'branch',
    Phone = 'phone',
    Address = 'address'
}

export enum ContinueApplicationEnum {
    Transaction = '1',
    Sms = '2'
}

export enum TransactionStatusEnum {
    Undefined = '',
    Denied = '0',
    Accepted = '1',
    Continuing = '2'
}

export enum CheckInfoImageEnum {
    BankCodeImg,
    BranchCodeImg,
    CheckNoImg,
    AccountNoImg,
    DrawerBankInfoImg
}

export enum ButtonInfoTitleTextEnum {
    BankCodeTitle ,
    BranchCodeTitle,
    CheckNoTitle,
    AccountNoTitle,
    DrawerBankInfoTitle
}
