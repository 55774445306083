import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import localeTrExtra from '@angular/common/locales/extra/tr';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInjector } from './app-injector.service';
import { AppService } from './app.service';
import { HttpInterceptorService } from './core/services/http-interceptor.service';
import { SharedModule } from './shared/shared.module';
import 'src/app/core/utils/string-utils';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

registerLocaleData(localeTr, 'tr-TR', localeTrExtra);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    NgxSpinnerModule
  ],
  providers: [
    AppService,
    AppInjector,
    {
      provide: APP_INITIALIZER,
      useFactory: appFactory,
      deps: [AppService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    { provide: UrlSerializer ,
      useClass: AppModule }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(injector: Injector) {
    console.log('Expected #1: storing app injector');
    AppInjector.setInjector(injector);
  }


  private defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();
  parse(url: string): UrlTree {
    return this.defaultUrlSerializer.parse(url);
}

serialize(tree: UrlTree): string {
    return this.defaultUrlSerializer.serialize(tree).replace(/%3F/, '?').replace(/%3D/, '=');
}
}


export function appFactory(appService: AppService) {
  return () => appService.load();
}





