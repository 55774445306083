export class CoreConstants {
    // Token
    public static COOKIE_TOKEN_KEY = 'FaktoringWebBasvuru';
    public static COOKIE_TOKEN_VALUE = '{0} {1}';
    // Login
    public static LOGIN = 'Login/VerifyUser';
    public static CUSTOMER_INFO = 'Apply/GetCustomerInfo';
    public static CLEAR_SESSION = 'Apply/ClearSession';
    public static LOGIN_REDIRECT = 'loginRedirect';

    //DigitalBanking Login
    public static DIGITAL_BANKING_LOGIN = 'Login/DigitalBankingVerifyUser';
}
