import * as tslib_1 from "tslib";
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { Subject } from 'rxjs';
var SmsModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SmsModalComponent, _super);
    function SmsModalComponent(modalRef) {
        var _this = _super.call(this) || this;
        _this.modalRef = modalRef;
        // CMS
        _this.resourceType = 'generalresource';
        _this.resourceKey = 'smsmodal';
        //
        _this.outputSubject = new Subject();
        _this.closeSubject = new Subject();
        _this.smsForm = {
            smsInput: ''
        };
        _this.isSubmitClicked = false;
        return _this;
    }
    SmsModalComponent.prototype.clickSumbitButton = function () {
        this.outputSubject.next(this.smsForm.smsInput);
        this.outputSubject.complete();
        this.modalRef.hide();
    };
    SmsModalComponent.prototype.close = function (isClosed) {
        this.closeSubject.next(true);
        this.closeSubject.complete();
    };
    return SmsModalComponent;
}(BaseComponent));
export { SmsModalComponent };
