/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../custom-modal/custom-modal.component.ngfactory";
import * as i6 from "../custom-modal/custom-modal.component";
import * as i7 from "@angular/common";
import * as i8 from "./message-modal.component";
import * as i9 from "ngx-bootstrap/modal";
var styles_MessageModalComponent = [i0.styles];
var RenderType_MessageModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageModalComponent, data: {} });
export { RenderType_MessageModalComponent as RenderType_MessageModalComponent };
function View_MessageModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.icon; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).title; var currVal_1 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_MessageModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn"]], [[2, "btn-secondary", null], [2, "btn-finans-ruby", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonAction({ close: false, first: true, second: false }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.secondButtonText; var currVal_1 = !_co.secondButtonText; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.firstButtonText; _ck(_v, 2, 0, currVal_2); }); }
function View_MessageModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-finans-ruby"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonAction({ close: false, first: false, second: true }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.secondButtonText; _ck(_v, 2, 0, currVal_0); }); }
export function View_MessageModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "app-custom-modal", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = (_co.close($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CustomModalComponent_0, i5.RenderType_CustomModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.CustomModalComponent, [], { title: [0, "title"], modalRef: [1, "modalRef"] }, { closed: "closed" }), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "div", [["class", "text-center ruby-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageModalComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageModalComponent_2)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageModalComponent_3)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.modalRef; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.icon; _ck(_v, 4, 0, currVal_2); var currVal_4 = _co.firstButtonText; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.secondButtonText; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.body; _ck(_v, 5, 0, currVal_3); }); }
export function View_MessageModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-modal", [], null, null, null, View_MessageModalComponent_0, RenderType_MessageModalComponent)), i1.ɵdid(1, 49152, null, 0, i8.MessageModalComponent, [i9.BsModalRef], null, null)], null, null); }
var MessageModalComponentNgFactory = i1.ɵccf("app-message-modal", i8.MessageModalComponent, View_MessageModalComponent_Host_0, { title: "title", body: "body", icon: "icon", firstButtonText: "firstButtonText", secondButtonText: "secondButtonText" }, {}, []);
export { MessageModalComponentNgFactory as MessageModalComponentNgFactory };
