import { Injectable } from '@angular/core';
import { Observable, AsyncSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoreConstants } from '../constants/core-constants';
import { CoreDataService } from './core-data.service';
import { LoginResponseModel, LoginModel } from '../models/login-response.model';
import { HttpService } from './http.service';
import { CustomerInfoResponse } from '../models/customer-info.model';
import { GeneralResponseModel } from '../models/general-response.model';
import { DigitalBankingLoginResponseModel } from '../models/digitalBankingLogin-response.model';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpService, private coreData: CoreDataService) {}

  login(
    identity?: string,
    phone?: string,
    sms?: string,
    gdpr?: boolean,
    isAdvertisement ?: boolean
  ): Observable<LoginResponseModel> {
    const data: any = {
      IsChecked: gdpr
    };
    if (identity) {
      data.UserCode = identity;
    }
    if (phone) {
      data.PhoneNumber = phone;
    }
    if (sms) {
      data.SmsCode = sms;
    }
    data.IsAdvertisement = isAdvertisement !== undefined ? isAdvertisement : false;

    const subject = new AsyncSubject<LoginResponseModel>();
    this.http.post<LoginModel>(CoreConstants.LOGIN, data).subscribe(
      response => {
        const IsSmsRequired = response.ResultData && response.ResultData.ISSMSREQUIRED;
        const IsSuccess = response.IsSuccess;
        subject.next({
          IsSuccess,
          ResultMessage: response.ResultMessage,
          IsSmsRequired
        });
        subject.complete();
      },
      error => {
        subject.next({
          IsSuccess: false,
          ResultMessage: '',
          IsSmsRequired: false
        });
        subject.complete();
      }
    );
    return subject;
  }

  isLoggedIn(): boolean {
    return this.coreData.token ? true : false;
  }

  getSessionLogin() {
    return this.http.get<CustomerInfoResponse>(CoreConstants.CUSTOMER_INFO);
  }

  clearSession() {
    this.http.get<GeneralResponseModel>(CoreConstants.CLEAR_SESSION).subscribe(
      response => {
        document.location.href = '/';
      },
      error => {
        document.location.href = '/';
      }
    );
  }

  digitalBankingLogin(digitalBankingToken?: string) {
    const data: any = {};
    if (digitalBankingToken) {
      data.DigitalBankingAccessToken = digitalBankingToken;
    }
    return this.http.post<DigitalBankingLoginResponseModel>(
      CoreConstants.DIGITAL_BANKING_LOGIN,
      data
    );
  }

}
