import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { faBars } from '@fortawesome/free-solid-svg-icons';
var SideNavigationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SideNavigationComponent, _super);
    function SideNavigationComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.sideNavOpen = false;
        _this.sideNavOpenChange = new EventEmitter();
        _this.faBars = faBars;
        return _this;
    }
    SideNavigationComponent.prototype.onContentClick = function () {
        this.changeSideNavState();
    };
    SideNavigationComponent.prototype.onSideNavClick = function () {
        this.changeSideNavState();
    };
    SideNavigationComponent.prototype.changeSideNavState = function () {
        this.sideNavOpen = !this.sideNavOpen;
        this.sideNavOpenChange.emit(this.sideNavOpen);
    };
    return SideNavigationComponent;
}(BaseComponent));
export { SideNavigationComponent };
