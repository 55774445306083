import { AsyncSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoreConstants } from '../constants/core-constants';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./core-data.service";
var AuthService = /** @class */ (function () {
    function AuthService(http, coreData) {
        this.http = http;
        this.coreData = coreData;
        this.baseUrl = environment.baseUrl;
    }
    AuthService.prototype.login = function (identity, phone, sms, gdpr, isAdvertisement) {
        var data = {
            IsChecked: gdpr
        };
        if (identity) {
            data.UserCode = identity;
        }
        if (phone) {
            data.PhoneNumber = phone;
        }
        if (sms) {
            data.SmsCode = sms;
        }
        data.IsAdvertisement = isAdvertisement !== undefined ? isAdvertisement : false;
        var subject = new AsyncSubject();
        this.http.post(CoreConstants.LOGIN, data).subscribe(function (response) {
            var IsSmsRequired = response.ResultData && response.ResultData.ISSMSREQUIRED;
            var IsSuccess = response.IsSuccess;
            subject.next({
                IsSuccess: IsSuccess,
                ResultMessage: response.ResultMessage,
                IsSmsRequired: IsSmsRequired
            });
            subject.complete();
        }, function (error) {
            subject.next({
                IsSuccess: false,
                ResultMessage: '',
                IsSmsRequired: false
            });
            subject.complete();
        });
        return subject;
    };
    AuthService.prototype.isLoggedIn = function () {
        return this.coreData.token ? true : false;
    };
    AuthService.prototype.getSessionLogin = function () {
        return this.http.get(CoreConstants.CUSTOMER_INFO);
    };
    AuthService.prototype.clearSession = function () {
        this.http.get(CoreConstants.CLEAR_SESSION).subscribe(function (response) {
            document.location.href = '/';
        }, function (error) {
            document.location.href = '/';
        });
    };
    AuthService.prototype.digitalBankingLogin = function (digitalBankingToken) {
        var data = {};
        if (digitalBankingToken) {
            data.DigitalBankingAccessToken = digitalBankingToken;
        }
        return this.http.post(CoreConstants.DIGITAL_BANKING_LOGIN, data);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.CoreDataService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
