export class GeneralConstants {
  // Error
  public static GENERAL_ERROR_RESOURCE = 'errortext';
  public static GENERAL_ERROR_TITLE = 'generalErrorTitleText';
  public static GENERAL_ERROR_TEXT = 'generalErrorText';
  public static GENERAL_ERROR_LOGIN_TEXT = 'generalErrorLoginText';
  public static GENERAL_ERROR_BUTTON_TEXT = 'generalErrorButtonText';
  // Input
  public static INPUT_ATTRIBUTE_TYPE = 'type';
  public static INPUT_TYPE_IMAGE = /image\/*/;
  public static INPUT_CLASS_CUSTOM_CONTROL = 'custom-control-input';
  public static INPUT_CLASS_FORM_CONTROL = 'form-control';
  public static INPUT_CLASS_INVALID = 'invalid';
  public static INPUT_ADD_CLASS_FORMAT = '{0} {1}';
  // Validations
  public static VALIDATION_IDENTITY_MASK = '00000000009';
  public static VALIDATION_VKN_MASK = '0000000000';
  public static VALIDATION_PHONE_PREFIX = '5';
  public static VALIDATION_PHONE_MASK = '000 000 00 00';
  public static VALIDATION_EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  public static VALIDATION_NUMBER_MASK = '09999999999999999999999999999';
  public static VALIDATION_CHECK_NUMBER_MASK = '0999999';
  public static VALIDATION_CHECK_ACCOUNT_NUMBER_MASK = '099999999999999';
  public static VALIDATION_CHECK_BANK_CODE = '9999';
  public static VALIDATION_CHECK_BRANCH_CODE = '09999';
  public static VALIDATION_DATE_MASK = '00/00/0000';
  public static VALIDATION_AMOUNT_MASK = 'separator.2';
  public static VALIDATION_AMOUNT_SEPARATOR = '.';
  public static VALIDATION_AMOUNT_SUFFIX = ' TL';
  // Configs
  public static CONFIG_BS_DATEPICKER = { isAnimated: true, dateInputFormat: 'DD/MM/YYYY' };
  public static OPTIONS_MODAL = {
    class: 'modal-dialog-centered',
    keyboard: false,
    ignoreBackdropClick: true
  };
  // Application
  public static APPLICATION_MAX_CHECK = 'applicationMaxCheck';
  public static APPLICATION_MIN_CHECK_AMOUNT = 'applicationMinCheckAmount';
  public static APPLICATION_MAX_CHECK_AMOUNT = 'applicationMaxCheckAmount';
  public static APPLICATION_MAX_INVOICE = 'applicationMaxInvoice';
  public static APPLICATION_MAX_IMAGE_SIZE = 'applicationMaxImageSize';
  public static APPLICATION_IMAGE_SCALE_RATIO = 'applicationImageScaleRatio';
  public static APPLICATION_IMAGE_QUALITY_RATIO = 'applicationImageQualityRatio';
  public static APPLICATION_CHECK_MIN_DATE = new Date();
  public static APPLICATION_EXCHANGE = 'TL';
  // Branch
  public static GOOGLE_MAPS_API_KEY = 'AIzaSyABBRBBYAB2CKvOPuNJpTzUAq7JNfm4bMU';
  public static INITIAL_LAT = 38.791705;
  public static INITIAL_LONG = 35.335437;
  public static ICON_WIDTH = 35;
  public static ICON_HEIGHT = 35;
}
