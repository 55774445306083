import * as tslib_1 from "tslib";
import { BaseComponent } from 'src/app/core/components/base/base.component';
var FooterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FooterComponent, _super);
    //
    function FooterComponent() {
        var _this = _super.call(this) || this;
        // CMS
        _this.resourceType = 'footer';
        _this.resourceKey = 'info';
        return _this;
    }
    return FooterComponent;
}(BaseComponent));
export { FooterComponent };
