import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {

  @Input() title: string;
  @Input() modalRef: BsModalRef;
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  faTimesCircle = faTimesCircle;
  constructor() { }

  ngOnInit() {
  }

  close() {
    this.modalRef.hide();
    this.closed.emit(true);
  }

}
