import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { RouterTransformerDirective } from './directives/router-transformer.directive';
import { BaseComponent } from './components/base/base.component';
import { HttpService } from './services/http.service';
import { AuthService } from './services/auth.service';
import { CoreDataService } from './services/core-data.service';
import { ResourceService } from './services/resource.service';
import { CanActivateViaAuthGuardService } from './services/can-activate-via-auth-guard.service';
import { DigitOnlyDirective } from './directives/digit-only.directive';



@NgModule({
  declarations: [SafePipe, RouterTransformerDirective, BaseComponent, DigitOnlyDirective],
  imports: [
    CommonModule
  ],
  exports: [SafePipe, RouterTransformerDirective, DigitOnlyDirective, BaseComponent],
  providers: [
    HttpService,
    AuthService,
    CanActivateViaAuthGuardService,
    CoreDataService,
    ResourceService
  ]
})
export class CoreModule { }
