import { Routes } from '@angular/router';
import { RouteConstants } from './shared/constants/route-constants';
var ɵ0 = function () { return import("./pages/application-page/application-page.module.ngfactory").then(function (m) { return m.ApplicationPageModuleNgFactory; }); }, ɵ1 = function () { return import("./pages/help/help.module.ngfactory").then(function (m) { return m.HelpModuleNgFactory; }); }, ɵ2 = function () { return import("./pages/contact-us/contact-us.module.ngfactory").then(function (m) { return m.ContactUsModuleNgFactory; }); }, ɵ3 = function () { return import("./pages/redirected-message-page/redirected-message-page.module.ngfactory").then(function (m) { return m.RedirectedMessagePageModuleNgFactory; }); }, ɵ4 = function () { return import("./pages/settings-page/settings-page.module.ngfactory").then(function (m) { return m.SettingsPageModuleNgFactory; }); }, ɵ5 = function () { return import("./pages/transactions/transactions.module.ngfactory").then(function (m) { return m.TransactionsModuleNgFactory; }); }, ɵ6 = function () { return import("./pages/not-found/not-found.module.ngfactory").then(function (m) { return m.NotFoundModuleNgFactory; }); };
var routes = [
    {
        path: '',
        redirectTo: RouteConstants.APPLICATION,
        pathMatch: 'full'
    },
    {
        path: RouteConstants.APPLICATION,
        loadChildren: ɵ0
    },
    {
        path: RouteConstants.HELP,
        loadChildren: ɵ1
    },
    {
        path: RouteConstants.CONTACT_US,
        loadChildren: ɵ2
    },
    {
        path: RouteConstants.REDIRECTED_MESSAGE,
        loadChildren: ɵ3
    },
    {
        path: RouteConstants.SETTINGS,
        loadChildren: ɵ4
    },
    {
        path: RouteConstants.TRANSACTIONS,
        loadChildren: ɵ5
    },
    {
        path: '**',
        loadChildren: ɵ6
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
