import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, AfterViewInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { String } from 'typescript-string-operations';
import { AppInjector } from 'src/app/app-injector.service';
import { ResourceService } from '../../services/resource.service';
var BaseComponent = /** @class */ (function () {
    function BaseComponent() {
        var injector = AppInjector.getInjector();
        this.resourceService = injector.get(ResourceService);
        this.routerService = injector.get(Router);
    }
    BaseComponent.prototype.customOnInit = function () { };
    BaseComponent.prototype.customAfterViewInit = function () { };
    BaseComponent.prototype.customOnDestroy = function () { };
    BaseComponent.prototype.customOnChanges = function (changes) { };
    BaseComponent.prototype.ngOnInit = function () {
        // cms
        this.setPageContent();
        this.customOnInit();
    };
    BaseComponent.prototype.ngAfterViewInit = function () {
        this.customAfterViewInit();
    };
    BaseComponent.prototype.ngOnDestroy = function () {
        this.customOnDestroy();
    };
    BaseComponent.prototype.ngOnChanges = function (changes) {
        this.customOnChanges(changes);
    };
    BaseComponent.prototype.onRefresh = function () {
        location.reload();
    };
    BaseComponent.prototype.setPageContent = function () {
        var e_1, _a;
        if (!this.resourceKey) {
            return;
        }
        console.log(this.resourceKey);
        var resourceItems = [];
        var isResource = !String.IsNullOrWhiteSpace(this.resourceType);
        if (isResource) {
            resourceItems = this.resourceService.getComponentContent(this.resourceType, this.resourceKey);
        }
        else {
            resourceItems = this.resourceService.getComponentContent(this.routerService.url, this.resourceKey);
        }
        try {
            for (var resourceItems_1 = tslib_1.__values(resourceItems), resourceItems_1_1 = resourceItems_1.next(); !resourceItems_1_1.done; resourceItems_1_1 = resourceItems_1.next()) {
                var resource = resourceItems_1_1.value;
                console.log('resource key: ' + resource.key + ' resource value: ' + resource.value);
                if (resource.value) {
                    this[resource.key] = resource.value;
                }
                else {
                    this[resource.key] = String.Format('{0}_{1}', this.resourceService.cultureCode, resource.key).toLowerCase();
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (resourceItems_1_1 && !resourceItems_1_1.done && (_a = resourceItems_1.return)) _a.call(resourceItems_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    return BaseComponent;
}());
export { BaseComponent };
