<div class="footer">
  <div class="container">
    <p>
      <span class="font-weight-bold">{{ addressSpan }} </span>
      {{ addressText }}
    </p>
    <p>
      <span class="font-weight-bold">{{ phoneSpan }} </span>
      {{ phoneText }}
    </p>
    <p>
      <span class="font-weight-bold">{{ faxSpan }}</span>
      {{ faxText }}
    </p>
  </div>
</div>
