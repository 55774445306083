import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { AppInjector } from './app/app-injector.service';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {};
    window.console.error = () => {};
    window.console.warn = () => {};
    window.console.error = () => {};
    window.console.time = () => {};
    window.console.timeEnd = () => {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(moduleRef => {
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch(err => console.error(err));
