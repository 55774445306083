<app-custom-modal
  [modalRef]="modalRef"
  [title]="title"
  (closed)="close($event)"
>
  <div class="text-center ruby-text">
    <div class="icon" *ngIf="icon">
      <fa-icon [icon]="icon"></fa-icon>
    </div>
    <div [innerHTML]="body"></div>
    <br />
    <div class="text-center">
      <span *ngIf="firstButtonText">
        <button
          class="btn"
          [class.btn-secondary]="secondButtonText"
          [class.btn-finans-ruby]="!secondButtonText"
          (click)="buttonAction({ close: false, first: true, second: false })"
        >
          {{ firstButtonText }}
        </button>
      </span>
      <span *ngIf="secondButtonText">
        <button
          class="btn btn-finans-ruby"
          (click)="buttonAction({ close: false, first: false, second: true })"
        >
          {{ secondButtonText }}
        </button>
      </span>
    </div>
  </div>
</app-custom-modal>
