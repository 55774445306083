import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
  animations: [
    SharedAnimations.inOutAnimetion
  ]
})
export class SideNavigationComponent extends BaseComponent {

  @Input() sideNavOpen = false;
  @Output() sideNavOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  faBars = faBars;

  onContentClick() {
    this.changeSideNavState();
  }

  onSideNavClick() {
    this.changeSideNavState();
  }

  changeSideNavState() {
    this.sideNavOpen = !this.sideNavOpen;
    this.sideNavOpenChange.emit(this.sideNavOpen);
  }
}
