import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { String } from 'typescript-string-operations';
import { CoreConstants } from '../constants/core-constants';
import { CoreDataService } from './core-data.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private coreData: CoreDataService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(JSON.stringify(req));

    const token = this.coreData.token;

    if (token) {
      const tokenStr = String.Format(CoreConstants.COOKIE_TOKEN_VALUE, token.type.toTitleCase(), token.token);
      req = req.clone({ headers: req.headers.set('Authorization', tokenStr) });
    }

    req = req.clone({ headers: req.headers.set('Cache-Control', 'no-cache') });
    req = req.clone({ headers: req.headers.set('Pragma', 'no-cache') });
    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    req = req.clone({ withCredentials: true });
    // Response
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
