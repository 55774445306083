<ul class="nav navbar-nav ml-auto">
  <li
    class="nav-item"
    [class.active]="
      currentUrl.includes(RouteConstants.APPLICATION) ||
      currentUrl === RouteConstants.BLANK
    "
    (click)="sideNavToggle()"
  >
    <a
      class="nav-link"
      [ngClass]="{ 'ruby-text': isSideMenu }"
      [routerLink]="[RouteConstants.APPLICATION]"
      routerLinkActive="router-link-active"
    >
      <fa-icon [icon]="faHome"></fa-icon>
      {{ homeText }}
      <span class="sr-only">(current)</span>
    </a>
  </li>
  <li
    class="nav-item"
    [class.active]="currentUrl.includes(RouteConstants.HELP)"
    (click)="sideNavToggle()"
  >
    <a
      class="nav-link"
      [ngClass]="{ 'ruby-text': isSideMenu }"
      [routerLink]="[RouteConstants.HELP]"
      routerLinkActive="router-link-active"
    >
      <fa-icon [icon]="faQuestionCircle"></fa-icon>
      {{ helpText }}
    </a>
  </li>
  <li
    class="nav-item"
    [class.active]="currentUrl.includes(RouteConstants.CONTACT_US)"
    (click)="sideNavToggle()"
  >
    <a
      class="nav-link"
      [ngClass]="{ 'ruby-text': isSideMenu }"
      [routerLink]="[RouteConstants.CONTACT_US]"
      routerLinkActive="router-link-active"
    >
      <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
      {{ contactText }}
    </a>
  </li>
  <li
    class="nav-item"
    [class.active]="currentUrl.includes(RouteConstants.TRANSACTIONS)"
    (click)="sideNavToggle()"
  >
    <a
      class="nav-link"
      [ngClass]="{ 'ruby-text': isSideMenu }"
      [routerLink]="[RouteConstants.TRANSACTIONS]"
      routerLinkActive="router-link-active"
    >
      <fa-icon [icon]="faHistory"></fa-icon>
      {{ historyText }}
    </a>
  </li>
  <li
    class="nav-item"
    [class.active]="currentUrl.includes(RouteConstants.SETTINGS)"
    (click)="sideNavToggle()"
  >
    <a
      class="nav-link"
      [ngClass]="{ 'ruby-text': isSideMenu }"
      [routerLink]="[RouteConstants.SETTINGS]"
      routerLinkActive="router-link-active"
    >
      <fa-icon [icon]="faCog"></fa-icon>
      {{ settingsText }}
    </a>
  </li>
</ul>
