import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppInjector } from './app/app-injector.service';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
    if (window) {
        window.console.log = function () { };
        window.console.error = function () { };
        window.console.warn = function () { };
        window.console.error = function () { };
        window.console.time = function () { };
        window.console.timeEnd = function () { };
    }
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(function (moduleRef) {
    AppInjector.setInjector(moduleRef.injector);
})
    .catch(function (err) { return console.error(err); });
