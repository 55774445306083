import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HttpService = /** @class */ (function () {
    function HttpService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
    }
    HttpService.prototype.get = function (url, params) {
        var newUrl = this.baseUrl.concat(url);
        if (params) {
            newUrl = newUrl.concat('?').concat(params.toString());
        }
        return this.http.get(newUrl);
    };
    HttpService.prototype.post = function (url, body, headers) {
        if (body === void 0) { body = {}; }
        var newUrl = this.baseUrl.concat(url);
        var options = {
            headers: headers,
        };
        return this.http.post(newUrl, body, options);
    };
    HttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.ɵɵinject(i1.HttpClient)); }, token: HttpService, providedIn: "root" });
    return HttpService;
}());
export { HttpService };
