import * as tslib_1 from "tslib";
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { String } from 'typescript-string-operations';
import { InputTypeEnum } from '../../models/enums.model';
import { GeneralConstants } from '../../constants/general-constants';
var CustomInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CustomInputComponent, _super);
    //
    function CustomInputComponent() {
        var _this = _super.call(this) || this;
        _this.withHyperLink = false;
        return _this;
    }
    CustomInputComponent.prototype.customOnInit = function () {
        this.addClass(this.element);
        if (this.minNumber || this.maxNumber) {
            this.controlMinMaxNumber(this.elementModel, this.minNumber, this.maxNumber);
        }
    };
    CustomInputComponent.prototype.customOnChanges = function (changes) {
        if (changes.isSubmitClicked && this.isSubmitClicked && this.element) {
            this.element.className += ' ' + GeneralConstants.INPUT_CLASS_INVALID;
            this.element.className = String.Format(GeneralConstants.INPUT_ADD_CLASS_FORMAT, this.element.className, GeneralConstants.INPUT_CLASS_INVALID);
        }
    };
    CustomInputComponent.prototype.addClass = function (element) {
        if (!element) {
            return;
        }
        if (element[GeneralConstants.INPUT_ATTRIBUTE_TYPE] === InputTypeEnum.Checkbox) {
            element.className = String.Format(GeneralConstants.INPUT_ADD_CLASS_FORMAT, element.className, GeneralConstants.INPUT_CLASS_CUSTOM_CONTROL);
        }
        else if (!element.className.includes(GeneralConstants.INPUT_CLASS_FORM_CONTROL)) {
            element.className = String.Format(GeneralConstants.INPUT_ADD_CLASS_FORMAT, element.className, GeneralConstants.INPUT_CLASS_FORM_CONTROL);
        }
    };
    CustomInputComponent.prototype.controlMinMaxNumber = function (model, min, max) {
        model.control.valueChanges.subscribe(function (value) {
            if (+value < min) {
                model.control.setErrors({ min: true });
            }
            else if (max && +value > max) {
                model.control.setErrors({ max: true });
            }
            else {
                model.control.setErrors(null);
            }
        });
    };
    return CustomInputComponent;
}(BaseComponent));
export { CustomInputComponent };
