import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CoreDataService {

  private state: object = {};
  user: any;
  token: any;
  resource: any = {};
  constructor() { }

  // State
  setState(key: string, value: any) {
    if (key) {
      this.state[key] = value;
    }
  }
  getState<T>(key: string): T {
    if (key) {
      return this.state[key] as T;
    }
  }
  deleteState(key: string): void {
    if (key) {
      delete this.state[key];
    }
  }
  clearState(): object {
    return this.state = {};
  }
  getAllState(): object {
    console.log(this.state);
    return this.state;
  }

}
