/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./digitalbanking-kvkk-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../custom-modal/custom-modal.component.ngfactory";
import * as i3 from "../custom-modal/custom-modal.component";
import * as i4 from "@angular/forms";
import * as i5 from "../custom-input/custom-input.component.ngfactory";
import * as i6 from "../custom-input/custom-input.component";
import * as i7 from "./digitalbanking-kvkk-modal.component";
import * as i8 from "ngx-bootstrap/modal";
var styles_DigitalbankingKvkkModalComponent = [i0.styles];
var RenderType_DigitalbankingKvkkModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DigitalbankingKvkkModalComponent, data: {} });
export { RenderType_DigitalbankingKvkkModalComponent as RenderType_DigitalbankingKvkkModalComponent };
export function View_DigitalbankingKvkkModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "app-custom-modal", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = (_co.close($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomModalComponent_0, i2.RenderType_CustomModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.CustomModalComponent, [], { title: [0, "title"], modalRef: [1, "modalRef"] }, { closed: "closed" }), (_l()(), i1.ɵeld(2, 0, null, 0, 23, "div", [["class", "width-md center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 22, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = ((i1.ɵnov(_v, 5).form.valid ? _co.clickSubmitButton() : _co.isSubmitClicked = true) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(5, 4210688, [["dbForm", 4]], 0, i4.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i4.ControlContainer, null, [i4.NgForm]), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 9, "app-custom-input", [], null, null, null, i5.View_CustomInputComponent_0, i5.RenderType_CustomInputComponent)), i1.ɵdid(9, 4964352, null, 0, i6.CustomInputComponent, [], { label: [0, "label"], element: [1, "element"], elementModel: [2, "elementModel"], errorMessage: [3, "errorMessage"], isSubmitClicked: [4, "isSubmitClicked"], withHyperLink: [5, "withHyperLink"] }, null), (_l()(), i1.ɵeld(10, 0, [["digitalBankingGDPRRef", 1]], 0, 7, "input", [["id", "digitalBankingForm"], ["name", "digitalBankingForm"], ["required", ""], ["type", "checkbox"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.digitalBankingForm = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i4.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵdid(12, 16384, null, 0, i4.CheckboxRequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.CheckboxRequiredValidator]), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.CheckboxControlValueAccessor]), i1.ɵdid(15, 671744, [["digitalBankingGDPRModelRef", 4]], 0, i4.NgModel, [[2, i4.ControlContainer], [6, i4.NG_VALIDATORS], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(17, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "a", [["href", "https://www.qnbff.com/gizlilik-ve-cerez-politikasi"], ["style", "padding-left: 22px;white-space: pre"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Uygulamalar\u0131m\u0131za ait kullan\u0131m ko\u015Fullar\u0131na buradan ula\u015Fabilirsiniz."])), (_l()(), i1.ɵeld(22, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "button", [["class", "btn btn-finans-ruby md-button"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((i1.ɵnov(_v, 5).form.valid ? _co.clickSubmitButton() : _co.isSubmitClicked = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(25, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.digitalBankingTitleText; var currVal_1 = _co.modalRef; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_9 = _co.formDigitalBankingGDPRLabel; var currVal_10 = i1.ɵnov(_v, 10); var currVal_11 = i1.ɵnov(_v, 15); var currVal_12 = _co.formDigitalBankingGDPRErrorText; var currVal_13 = _co.isSubmitClicked; var currVal_14 = true; _ck(_v, 9, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_23 = ""; _ck(_v, 12, 0, currVal_23); var currVal_24 = "digitalBankingForm"; var currVal_25 = _co.digitalBankingForm; _ck(_v, 15, 0, currVal_24, currVal_25); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 7).ngClassValid; var currVal_7 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_15 = (i1.ɵnov(_v, 12).required ? "" : null); var currVal_16 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_17 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_18 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_19 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_20 = i1.ɵnov(_v, 17).ngClassValid; var currVal_21 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_22 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 10, 0, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); var currVal_26 = _co.formDigitalBankingSubmitButtonText; _ck(_v, 25, 0, currVal_26); }); }
export function View_DigitalbankingKvkkModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-digitalbanking-kvkk-modal", [], null, null, null, View_DigitalbankingKvkkModalComponent_0, RenderType_DigitalbankingKvkkModalComponent)), i1.ɵdid(1, 4964352, null, 0, i7.DigitalbankingKvkkModalComponent, [i8.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DigitalbankingKvkkModalComponentNgFactory = i1.ɵccf("app-digitalbanking-kvkk-modal", i7.DigitalbankingKvkkModalComponent, View_DigitalbankingKvkkModalComponent_Host_0, { resourceKey: "resourceKey", resourceType: "resourceType", digitalBankingTitleText: "digitalBankingTitleText", formDigitalBankingSubmitButtonText: "formDigitalBankingSubmitButtonText", formDigitalBankingGDPRLabel: "formDigitalBankingGDPRLabel", formDigitalBankingGDPRErrorText: "formDigitalBankingGDPRErrorText", formDigitalBankingAPRLabel: "formDigitalBankingAPRLabel", isSubmitClicked: "isSubmitClicked", digitalBankingForm: "digitalBankingForm" }, {}, []);
export { DigitalbankingKvkkModalComponentNgFactory as DigitalbankingKvkkModalComponentNgFactory };
