<div class="header">
  <nav class="navbar navbar-expand-lg navbar-dark">
    <a
      class="navbar-brand"
      href= "https://www.qnbff.com/" target="_blank"
    >
      <img [src]="Images.Logo" alt="" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      (click)="sideNavOpen = !sideNavOpen"
      aria-controls="collapseBasic"
    >
      <fa-icon [icon]="faBars"></fa-icon>
    </button>
    <div
      class="navbar-collapse collapse show"
      id="collapseBasic"
      [collapse]="true"
      [isAnimated]="true"
    >
      <app-navigation-menu
        class="ml-auto"
        [isSideMenu]="false"
      ></app-navigation-menu>
    </div>
  </nav>
</div>

<app-side-navigation class=".d-lg-none" [(sideNavOpen)]="sideNavOpen"> </app-side-navigation>
