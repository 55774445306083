<div class="modal-header" [class.with-label]="title">
  <div class="row title-row">
    <div class="col-auto">
      <label>
        <b>{{ title }}</b>
      </label>
    </div>
    <div class="col">
      <div class="text-right">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="close()"
        >
          <fa-icon [icon]="faTimesCircle"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>
