interface String {
    toTitleCase(): string;
    isNumeric(): boolean;
    addBase64(): string;
    removeBase64(): string;
    replaceAll(search: string, replacement: string): string;
}
interface Array<T> {
    toTitleCase(): Array<T>;
}


String.prototype.toTitleCase = function() {
    return (this as string).toLowerCase().split(' ').map((word) => {
        return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
};

String.prototype.isNumeric = function() {
    return !isNaN(parseFloat(this)) && isFinite(this);
};

Array.prototype.toTitleCase = function() {
    return (this as Array<string>).map(a => this.toLowerCase().split(' ').map((word) => {
        return word.replace(word[0], word[0].toUpperCase());
    }).join(' '));
};

String.prototype.removeBase64 = function() {
    if ((this as string).includes('base64,')) {
        return (this as string).split('base64,')[1];
    }
    return this as string;
};

String.prototype.addBase64 = function() {
    if ((this as string).includes('base64,')) {
        return (this as string);
    }
    return 'data:image/png;base64,'.concat(this as string);
};

String.prototype.replaceAll = function(search: string, replacement: string) {
    return this.split(search).join(replacement);
};
