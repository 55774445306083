<app-header></app-header>
<div id="mainContent" class="container-fluid">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
<!-- Loading -->
<ngx-spinner
  bdOpacity="1"
  bdColor="rgba(72,0,42,0.8)"
  size="medium"
  color="#fff"
  type="timer"
  [fullScreen]="true"
>
  <p style="color: white"></p>
</ngx-spinner>
