import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  get<T>(url: string, params?: HttpParams): Observable<T> {
    let newUrl = this.baseUrl.concat(url);
    if (params) {
      newUrl = newUrl.concat('?').concat(params.toString());
    }
    return this.http.get<T>(newUrl);
  }

  post<T>(url: string, body: any = {}, headers?: HttpHeaders): Observable<T> {
    const newUrl = this.baseUrl.concat(url);
    const options = {
      headers,
    };
    return this.http.post<T>(newUrl, body, options);
  }
}
