import { Component, OnInit, OnDestroy, AfterViewInit, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { String } from 'typescript-string-operations';
import { AppInjector } from 'src/app/app-injector.service';
import { ResourceService } from '../../services/resource.service';
import { KeyValuePair } from '../../models/keyValuePair.model';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  /**
   * https://devblogs.microsoft.com/premier-developer/angular-how-to-simplify-components-with-typescript-inheritance/
   */
  @Input() resourceKey: string;
  @Input() resourceType: string;
  //
  protected resourceService: ResourceService;
  protected routerService: Router;
  constructor() {
    const injector = AppInjector.getInjector();
    this.resourceService = injector.get(ResourceService);
    this.routerService = injector.get(Router);
  }
  public customOnInit() { }
  public customAfterViewInit() { }
  public customOnDestroy() { }
  public customOnChanges(changes: any) { }

  ngOnInit() {
    // cms
    this.setPageContent();
    this.customOnInit();
  }
  ngAfterViewInit() {
    this.customAfterViewInit();
  }
  ngOnDestroy() {
    this.customOnDestroy();
  }
  ngOnChanges(changes: any) {
    this.customOnChanges(changes);
  }

  onRefresh() {
    location.reload();
  }

  public setPageContent() {
    if (!this.resourceKey) {
      return;
    }
    console.log(this.resourceKey);
    let resourceItems: KeyValuePair[] = [];
    const isResource: boolean = !String.IsNullOrWhiteSpace(this.resourceType);
    if (isResource) {
      resourceItems = this.resourceService.getComponentContent(this.resourceType, this.resourceKey);
    } else {
      resourceItems = this.resourceService.getComponentContent(this.routerService.url, this.resourceKey);
    }

    for (const resource of resourceItems) {

      console.log('resource key: ' + resource.key + ' resource value: ' + resource.value);

      if (resource.value) {
        this[resource.key] = resource.value;
      } else {
        this[resource.key] = String.Format('{0}_{1}', this.resourceService.cultureCode, resource.key).toLowerCase();
      }

    }
  }

}
