<div class="form-group">
  <label *ngIf="label && element?.type !== 'checkbox'">{{ label }}</label>
  <div
    #inputContent
    [class.custom-control]="element?.type === 'checkbox'"
    [class.custom-checkbox]="element?.type === 'checkbox'"
    [class.invalid-checkbox-border]="
      element?.type === 'checkbox' && !elementModel?.model && isSubmitClicked
    "
  >
    <ng-content></ng-content>
    <label
      *ngIf="label && element?.type === 'checkbox' && !withHyperLink"
      class="custom-control-label"
      [for]="element?.id"
    >
      {{ label }}
    </label>
    <label
      *ngIf="label && element?.type === 'checkbox' && withHyperLink"
      class="custom-control-label"
      [for]="element?.id"
      [innerHTML]="label"
      appRouterTransformer
    >
    </label>
  </div>

  <small
    class="form-text text-danger"
    *ngIf="
      (elementModel?.invalid && (elementModel?.touched || isSubmitClicked)) ||
      (element?.type === 'checkbox' && (!elementModel?.model && isSubmitClicked))
    "
  >
    {{ errorMessage }}
  </small>
</div>
