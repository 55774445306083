export class RouteConstants {

    public static BLANK = '/';
    public static HELP = 'yardim';
    public static HISTORY = 'islemlerim';
    public static SETTINGS = 'ayarlar';
    public static REDIRECTED_MESSAGE = 's';
    // Contact Us
    public static CONTACT_US = 'bize_ulasin';
    public static CONTACT_US_BRANCHES = 'subeler';
    public static CONTACT_US_PHONES = 'telefonlar';
    public static CONTACT_US_ADDRESSES = 'adresler';
    // Application
    public static APPLICATION = 'basvuru';
    public static APPLICATION_LOGIN = 'giris';
    public static APPLICATION_START = 'baslangic';
    public static APPLICATION_START_ADV = 'baslangic:advertisment';
    public static APPLICATION_VKN = 'vkn';
    public static APPLICATION_VKN_ADV = 'vkn:advertisment';
    public static APPLICATION_EMAIL = 'e_posta';
    public static APPLICATION_EMAIL_ADV = 'e_posta:advertisment';
    public static APPLICATION_UPLOAD_CHECK = 'cek_yukle';
    public static APPLICATION_UPLOAD_CHECK_ADV = 'cek_yukle:advertisment';
    public static APPLICATION_UPLOAD_CHECK_PHOTO = 'fotograf';
    public static APPLICATION_UPLOAD_CHECK_PHOTO_ADV = 'fotograf:advertisment';

    public static APPLICATION_UPLOAD_INVOICE = 'fatura_yukle';
    public static APPLICATION_UPLOAD_INVOICE_ADV = 'fatura_yukle:advertisment';
    public static APPLICATION_RESULT = 'sonuc';
    public static APPLICATION_RESULT_ADV = 'sonuc:advertisment';
    public static APPLICATION_RESULT_CHECK = 'cek';
    public static APPLICATION_RESULT_CHECK_PREVIEW = 'onizle';
    public static APPLICATION_RESULT_CHECK_EDIT = 'duzenle';
    public static APPLICATION_RESULT_CHECK_EDIT_PHOTO = 'fotograf_yukle';
    public static APPLICATION_RESULT_CHECK_UPLOAD = 'yukle';
    public static APPLICATION_RESULT_CHECK_UPLOAD_PHOTO = 'fotograf';
    public static APPLICATION_RESULT_INVOICE_UPLOAD = 'fatura_yukle';
    // Transactions
    public static TRANSACTIONS = 'islemlerim';
    public static TRANSACTIONS_INFO = ':id';
    public static TRANSACTIONS_CHECK_INFO = ':id';

    public static APPLICATION_ADVERTISMENT = '?advertisment=true';

     //Digital Banking Acces Token
     public static APPLICATION_DIGITAL_BANKING_LOGINTOKEN = 'giris?access_token=';
}
