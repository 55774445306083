/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-navigation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../navigation-menu/navigation-menu.component.ngfactory";
import * as i3 from "../navigation-menu/navigation-menu.component";
import * as i4 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./side-navigation.component";
var styles_SideNavigationComponent = [i0.styles];
var RenderType_SideNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideNavigationComponent, data: { "animation": [{ type: 7, name: "inOutAnimation", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { right: "-60%" }, offset: null }, { type: 4, styles: { type: 6, styles: { right: 0 }, offset: null }, timings: "0.35s ease" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { right: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { right: "-60%" }, offset: null }, timings: "0.35s ease" }], options: null }], options: {} }] } });
export { RenderType_SideNavigationComponent as RenderType_SideNavigationComponent };
function View_SideNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "side-content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onContentClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_SideNavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-menu", [["class", "side-nav"]], [[24, "@inOutAnimation", 0]], [[null, "isMenuOpenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isMenuOpenChange" === en)) {
        var pd_0 = (_co.onSideNavClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NavigationMenuComponent_0, i2.RenderType_NavigationMenuComponent)), i1.ɵdid(1, 4964352, null, 0, i3.NavigationMenuComponent, [], { isSideMenu: [0, "isSideMenu"], isMenuOpen: [1, "isMenuOpen"] }, { isMenuOpenChange: "isMenuOpenChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = _co.sideNavOpen; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_SideNavigationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "navbar-toggler ruby-text"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSideNavClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faBars; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).title; var currVal_1 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SideNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavigationComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavigationComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavigationComponent_3)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sideNavOpen; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.sideNavOpen; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.sideNavOpen; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_SideNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-navigation", [], null, null, null, View_SideNavigationComponent_0, RenderType_SideNavigationComponent)), i1.ɵdid(1, 4964352, null, 0, i8.SideNavigationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideNavigationComponentNgFactory = i1.ɵccf("app-side-navigation", i8.SideNavigationComponent, View_SideNavigationComponent_Host_0, { resourceKey: "resourceKey", resourceType: "resourceType", sideNavOpen: "sideNavOpen" }, { sideNavOpenChange: "sideNavOpenChange" }, []);
export { SideNavigationComponentNgFactory as SideNavigationComponentNgFactory };
