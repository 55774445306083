import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CoreDataService } from './core/services/core-data.service';
import Resource from '../assets/cms/resource.json';
@Injectable()
export class AppService {
  tasks = new Subject<boolean>();
  taskCompleteNumber = 0;
  taskTotalNumber = 1;
  constructor(private coreData: CoreDataService) { }

  load(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.tasks.subscribe(() => {
        this.taskCompleteNumber++;

        if (this.taskCompleteNumber === this.taskTotalNumber) {
          resolve(true);
        }
      });
      // Tasks
      this.taskGetResources();
    });
  }

  // Tasks
  private taskGetResources() {
    /*
    this.sharedService.getCmsResource().subscribe(
      response => {
        this.tasks.next(true);
      },
      error => {
        console.log(error);
        this.tasks.next(false);
      }
    );
    */
    this.coreData.resource = Resource;
    this.tasks.next(false);
  }
}
