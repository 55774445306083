import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { faBars, faHome, faQuestionCircle, faMapMarkerAlt, faHistory, faCog } from '@fortawesome/free-solid-svg-icons';
import { RouteConstants } from '../../constants/route-constants';
import { NavigationEnd, Event } from '@angular/router';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent extends BaseComponent {
  // CMS
  resourceType = 'generalresource';
  resourceKey = 'header';

  homeText: string;
  helpText: string;
  contactText: string;
  historyText: string;
  settingsText: string;
  //
  RouteConstants = RouteConstants;
  currentUrl = '';
  faBars = faBars;
  faHome = faHome;
  faQuestionCircle = faQuestionCircle;
  faMapMarkerAlt = faMapMarkerAlt;
  faHistory = faHistory;
  faCog = faCog;

  @Input() isSideMenu: boolean;
  @Input() isMenuOpen: boolean;
  @Output() isMenuOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    super();
    this.subscribeRouterService();
  }

  private subscribeRouterService() {
    this.routerService.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        console.log(event.url);
      }
    });
  }

  sideNavToggle() {
    if (!this.isSideMenu) { return; }
    this.isMenuOpen = false;
    this.isMenuOpenChange.emit(this.isMenuOpen);
  }
}
